import React from 'react'

export const Header = () => {
  return (
    <header>
      <h1 className="trafalgar">Here in the Hive</h1>
    </header>
  )
}

export default Header