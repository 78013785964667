import React from 'react'

export const Footer = () => {
  return (
    <footer>
      <p className="type-brevier">&copy; 2023 Dan Donald</p>
    </footer>
  )
}

export default Footer
