import React from 'react'

import Header from './components/header/header'
import Footer from './components/footer/footer'

import './App.css'

function App() {
  return (
    <>
      <Header />
      <main>
        Working in the open...very much under construction
      </main>
      <Footer />
    </>
  )
}

export default App
